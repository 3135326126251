export const registerServiceWorker = () => {
  if (
    'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window
  ) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then(function (registration) {
        console.log('[SW]: SCOPE: ', registration.scope);
        return registration.scope;
      })
      .catch(function (err) {
        return err;
      });
  }
};
