import { createSlice } from '@reduxjs/toolkit';

const roleSlice = createSlice({
  name: 'SET_ROLE',
  initialState: 'user',
  reducers: {
    setRole: (state, action) => {
      return action.payload;
    }
  }
});

export const { setRole } = roleSlice.actions;
export const roleReducer = roleSlice.reducer;
