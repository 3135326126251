import { createSlice } from '@reduxjs/toolkit';

const unreadSlice = createSlice({
  name: 'SET_UNREAD_COUNT',
  initialState: 0,
  reducers: {
    setUnreadCount: (state, action) => {
      return action.payload;
    },
  },
});

export const { setUnreadCount } = unreadSlice.actions;
export const unreadReducer = unreadSlice.reducer;
