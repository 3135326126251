import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
import { getToken, generateUUID } from './../utils';
import { API_BASE_URL } from '@env';

export const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: async (headers) => {
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    headers.set('correlation-id', generateUUID());
    const token = await getToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

export const QApi = createApi({
  reducerPath: 'QApi',
  baseQuery: baseQuery,
  refetchOnFocus: true,
  tagTypes: ['UserApi', 'QueueApi', 'UiApi'],
  endpoints: () => ({})
});
