/* eslint-disable react/prop-types */
/* eslint-disable react-native/no-unused-styles */
import React, { useMemo } from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import QText from './QText';
import { useTheme } from 'react-native-paper';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import AlertSuccessIcon from './Icons/AlertSuccessIcon';
import AlertErrorIcon from './Icons/AlertErrorIcon';
import CloseIcon from './Icons/CloseIcon';

import { TouchableOpacity } from 'react-native';
const width = Dimensions.get('window').width;
const ToastMessage = (props) => {
  const { type, hide, text1, text2 } = props;
  const theme = useTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { t } = useTranslation();
  let containerStyle = { backgroundColor: '#d1e7dd', borderColor: '#badbcc' };
  let textColor = '#0f5132';
  if (type === 'error') {
    containerStyle = { backgroundColor: '#f8d7da', borderColor: '#f5c2c7' };
    textColor = '#842029';
  }

  return (
    <View style={[styles.toastContainer, containerStyle]}>
      <View style={styles.toastRow}>
        {type === 'success' && <AlertSuccessIcon color={textColor} />}
        {type === 'error' && <AlertErrorIcon color={textColor} />}

        <View style={styles.toastText}>
          <QText style={{ fontWeight: 'bold', fontSize: 15, color: textColor }}>
            {text1}
          </QText>
          <QText style={{ fontSize: 12, marginTop: 3, color: textColor }}>
            {text2}
          </QText>
        </View>

        <TouchableOpacity onPress={hide}>
          <CloseIcon width={18} height={18} color={textColor} />
        </TouchableOpacity>
      </View>
    </View>
  );
};
const createStyles = (theme) =>
  StyleSheet.create({
    toastContainer: {
      padding: 10,
      width: Dimensions.get('window').width > 350 ? 350 : width - 50,
      borderWidth: 1,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 5,
    },
    toastRow: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    toastText: {
      width: '75%',
    },
  });
export default ToastMessage;
