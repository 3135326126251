import { QApi } from '../baseQuery';
import {
  USER_LOGIN,
  VERIFY_LOGIN,
  USER_GET_DETAIL,
  BUSINESS_DETAIL_CREATE,
  BUSINESS_DETAIL_UPDATE,
  UPDATE_PROFILE,
  HELP_AND_SUPPORT,
  FEEDBACK,
  GET_REPORT_DATA,
  GENERATE_REPORT,
  LOGOUT,
  GET_NOTIFICATION_LIST,
  DELETE_NOTIFICATION,
  UNREAD_COUNT,
  CONTACT_DETAIL_SEND,
  SEND_OTP,
  GET_SUBSCRIPTION_PLAN_LIST,
  USER_SUBSCRIBE_PLAN,
  GET_PAYMENT_HISTORY_LIST,
} from '../../config/ApiList';
export const UserApi = QApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => {
        return {
          url: USER_LOGIN,
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['UserApi'],
    }),
    verifyLogin: builder.mutation({
      query: (body) => {
        return {
          url: VERIFY_LOGIN,
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['UserApi'],
    }),
    getUserDetail: builder.query({
      query: () => {
        return {
          url: USER_GET_DETAIL,
          method: 'GET',
        };
      },
      invalidatesTags: ['UserApi'],
    }),
    businessDetailCreate: builder.mutation({
      query: (body) => {
        return {
          url: BUSINESS_DETAIL_CREATE,
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['UserApi'],
    }),
    businessDetailUpdate: builder.mutation({
      query: (body) => {
        return {
          url: BUSINESS_DETAIL_UPDATE + '/' + body?.id,
          method: 'PATCH',
          body: body?.params,
        };
      },
      transformErrorResponse: (response, meta, arg) => response,
      invalidatesTags: ['UserApi'],
    }),
    updateProfile: builder.mutation({
      query: (body) => {
        return {
          url: UPDATE_PROFILE,
          method: 'PATCH',
          body: body,
        };
      },
      transformErrorResponse: (response, meta, arg) => response,
      invalidatesTags: ['UserApi'],
    }),
    helpAndSupport: builder.mutation({
      query: (body) => {
        return {
          url: HELP_AND_SUPPORT,
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['UserApi'],
    }),
    feedBack: builder.mutation({
      query: (body) => {
        return {
          url: FEEDBACK,
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['UserApi'],
    }),
    getReportData: builder.query({
      query: () => {
        return {
          url: GET_REPORT_DATA,
          method: 'GET',
        };
      },
      invalidatesTags: ['UserApi'],
    }),
    generateReport: builder.mutation({
      query: ({ body, fileType }) => {
        let url = GENERATE_REPORT;
        if (fileType) {
          url = `${url}/${fileType}`;
          return {
            url,
            method: 'POST',
            body,
            responseHandler: (response) => response.blob(), // Parse response as Blob
          };
        } else {
          return {
            url: `${url}/json`,
            method: 'POST',
            body,
          };
        }
      },
      invalidatesTags: ['UserApi'],
    }),
    logout: builder.mutation({
      query: (body) => {
        return {
          url: LOGOUT,
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['UserApi'],
    }),
    getNotificationList: builder.query({
      query: (params) => {
        return {
          url: GET_NOTIFICATION_LIST,
          method: 'GET',
          params: params,
        };
      },
      invalidatesTags: ['UserApi'],
    }),
    deleteNotification: builder.mutation({
      query: (params) => {
        let url = DELETE_NOTIFICATION;
        if (params?.notification_id) {
          url = `${DELETE_NOTIFICATION}/${params.notification_id}`;
        }
        return {
          url: url,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['UserApi'],
    }),
    getUnreadCount: builder.query({
      query: () => {
        return {
          url: UNREAD_COUNT,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
      invalidatesTags: ['UserApi'],
    }),
    contactDetailSend: builder.mutation({
      query: (body) => {
        return {
          url: CONTACT_DETAIL_SEND,
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['UserApi'],
    }),

    verifyOtp: builder.mutation({
      query: (body) => {
        return {
          url: VERIFY_LOGIN,
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['UserApi'],
    }),
    sendOtp: builder.mutation({
      query: (body) => {
        return {
          url: SEND_OTP,
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['UserApi'],
    }),
    getSubscriptionPlanList: builder.query({
      query: () => {
        return {
          url: GET_SUBSCRIPTION_PLAN_LIST,
          method: 'GET',
        };
      },
      invalidatesTags: ['UserApi'],
    }),
    userSubscribePlan: builder.mutation({
      query: (body) => {
        return {
          url: USER_SUBSCRIBE_PLAN,
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['UserApi'],
    }),
    getPaymentHistoryList: builder.query({
      query: () => {
        return {
          url: GET_PAYMENT_HISTORY_LIST,
          method: 'GET',
        };
      },
      invalidatesTags: ['UserApi'],
    }),
  }),
});

export const {
  useLoginMutation,
  useVerifyLoginMutation,
  useVerifyOtpMutation,
  useGetUserDetailQuery,
  useBusinessDetailCreateMutation,
  useBusinessDetailUpdateMutation,
  useHelpAndSupportMutation,
  useFeedBackMutation,
  useGetReportDataQuery,
  useGenerateReportMutation,
  useLogoutMutation,
  useGetNotificationListQuery,
  useGetUnreadCountQuery,

  useSendOtpMutation,
  useUpdateProfileMutation,
  useGetSubscriptionPlanListQuery,
  useUserSubscribePlanMutation,
  useGetPaymentHistoryListQuery,
  useDeleteNotificationMutation,
  useContactDetailSendMutation,
} = UserApi;
