import React, { memo } from 'react';
import { Text } from 'react-native-paper';
import PropTypes from 'prop-types';
import { useTheme } from 'react-native-paper';

const QText = ({ style, children, ...props }) => {
  const theme = useTheme();
  return (
    <Text
      style={[
        {
          color: theme.colors.text,
          fontFamily: 'Poppins',
          fontSize: 13,
          fontWeight: 400,
        },
        style,
      ]}
      {...props}
    >
      {children}
    </Text>
  );
};

QText.propTypes = {
  style: PropTypes.any,
  children: PropTypes.any,
};

QText.defaultProps = {
  style: {},
};

export default memo(QText);
