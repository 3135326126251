import AsyncStorage from '@react-native-async-storage/async-storage';
import { WEB_SOCKET_URL, IMAGE_PATH } from '@env';
import moment, { min } from 'moment';
import { Platform, Dimensions, useWindowDimensions } from 'react-native';
import * as Sentry from 'sentry-expo';
import 'moment-timezone';

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');

export const weekDayNameList = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
];

export const removeItemLocalStorage = async (key) => await AsyncStorage.removeItem(key);

export const getToken = async () => await AsyncStorage.getItem('token');

export const setToken = async (token) => await AsyncStorage.setItem('token', token);

export const setFcmToken = async (token) => await AsyncStorage.setItem('fcm_token', token);

export const getFcmToken = async () => await AsyncStorage.getItem('fcm_token');

export const clearStorage = async () => await AsyncStorage.clear();

export const setQueueJoinId = async (id) => await AsyncStorage.setItem('uqid', id);

export const setUserInterestQueueParam = async (params) => await AsyncStorage.setItem('userInterestQueueParma', params);

export const getQueueJoinId = async () => await AsyncStorage.getItem('uqid');

export const getUserInterestQueueParam = async () => await AsyncStorage.getItem('userInterestQueueParma');

export const showDate = (date) => (date ? moment(date).format('DD MMM YYYY') : '');

export const showTime = (time) => (time ? moment(time, ['HH:mm']).format('hh:mm A') : '');

export const showDateUTC = (date) => (date ? moment.utc(date).format('DD MMM YYYY') : '');

export const showDateTime = (date) => (date ? moment(date).format('DD MMM YYYY hh:mm A') : '');

export const showDateTimeUTCtoLocal = (date) => (date ? moment.utc(date).local().format('DD MMM YYYY hh:mm A') : '');


export const setRoleLocalStorage = async (role) => await AsyncStorage.setItem('role', role);

export const getRoleLocalStorage = async () => await AsyncStorage.getItem('role');

export const getOnlyTimeAmPMFormat = (datetime) => (datetime ? moment(datetime).format("hh:mm A") : '');

export const getTimeDifferenceInSeconds = (targetTimeString, timezone) => {
  const now = moment().tz(timezone);
  const estimatedTime = moment.tz(targetTimeString, timezone);
  const diffInSeconds = estimatedTime.diff(now, 'seconds');
  return diffInSeconds;
};

export const setThemeLocalStorage = async (value) =>
  await AsyncStorage.setItem('isLightTheme', value);

export const getThemeLocalStorage = async () => await AsyncStorage.getItem('isLightTheme');

export const getWebSocketUrl = () => {
  if (Platform.OS === 'web') {
    let wsProtocol = location.protocol === 'http:' ? 'ws' : 'wss';
    let hostName = WEB_SOCKET_URL;
    return wsProtocol + '://' + hostName + '/ws';
  } else {
    let wsProtocol = 'wss';
    let hostName = WEB_SOCKET_URL;
    return wsProtocol + '://' + hostName + '/ws';
  }
};

export const getImageUrl = (fileName, dir = '') => {
  return IMAGE_PATH + dir + fileName;
};

export const getAvatarLabel = (name) => {
  let label = 'N/A';
  if(name){
    let nameList = name.split(' ');
    label = nameList[0].substring(0, 1).toUpperCase();
    if(nameList.length > 1){
      label += nameList[1].substring(0, 1).toUpperCase();
    }
  }
  return label;
};

export const goBackHandler = (navigation) => {
  // if (navigation.getState().routes.length > 1) {
  //     navigation.goBack();
  //   } else {
  //     navigation.push('DashboardScreen');
  //   }
  if (Platform.OS === 'web') {
    window.history.back();
  } else {
    navigation.goBack();
  }
};

export const errorLogOnServer = (msg) => {
  if (Platform.OS === 'web') {
    Sentry.Browser.captureException(msg);
  } else {
    Sentry.Native.captureException(msg);
  }
};


export const getWeekListForQueueCard = (weekList, holidayList) => {
  let dateArrayList = [];
  let holidayFilter =
    holidayList && holidayList.length > 0
      ? holidayList.filter((x) => moment(moment().format("YYYY-MM-DD")).isSameOrBefore(x.date) && x.serving_capacity === 0)
      : [];

  for (var i = 0; i < 7; i++) {
    let date = moment().add(i, 'days');
    let dayNumber = date.isoWeekday() - 1;
    let isHoliday = false;
    let isDayOff = true;
    let start_time = '';
    let end_time = '';
    let isAvailable = false;
    if (
      holidayFilter &&
      holidayFilter.filter((x) => date.format('YYYY-MM-DD') == x.date).length > 0
    ) {
      isHoliday = true;
    }

    if (weekList && weekList.length > 0 && isHoliday === false) {
      let filterWeekList = weekList.filter((x) => x.day_number === dayNumber);
      if (filterWeekList.length > 0) {
        isDayOff = false;
        if (filterWeekList[0]?.start_time) {
          start_time = filterWeekList[0]?.start_time;
        }
        if (filterWeekList[0]?.end_time) {
          end_time = filterWeekList[0]?.end_time;
          let beginningTime = moment(date.format('YYYY-MM-DD') + ' ' + filterWeekList[0]?.end_time);
          var endTime = moment();
          isAvailable = beginningTime.isAfter(endTime);
        }
      }
    }
    dateArrayList[i] = {
      date,
      text: date.format('dddd').substring(0, 1),
      dayNumber,
      isHoliday,
      isDayOff,
      start_time,
      end_time,
      isAvailable
    };
  }

  let availableDayID = '';
  let dateArrayIsAvailableList = dateArrayList.filter((x) => x.isAvailable === true);
  if (dateArrayIsAvailableList.length > 0) {
    availableDayID = dateArrayIsAvailableList[0].dayNumber;
  }

  if (availableDayID !== '') {
    dateArrayList = dateArrayList.map((item) => {
      item.isAvailable = false;
      if (availableDayID === item.dayNumber) {
        item.isAvailable = true;
      }
      return item;
    });
  }
  dateArrayList.sort(function (a, b) {
    return a.dayNumber - b.dayNumber;
  });

  return dateArrayList;
};

export const getDisabledHolidayListAdvancedBooking = (weekData, holidayData,allowAdvancedDay) => {

  let holidayList = holidayData.map((i) => new Date(i.date));
  let weekList = weekData;
  const results = [];
  let start = moment().format('YYYY-MM-DD');
  let end = moment().add(parseInt(allowAdvancedDay) , 'd').format('YYYY-MM-DD');
  let weekdayDisabled = [];
  for (let i = 0; i < 7; i++) {
    let filterWeekList = weekList.filter((x) => x.id === i);
    if(filterWeekList.length === 0){
      weekdayDisabled.push(i);
    }
  }
  
  while(moment(start) <= moment(end)){
    if(weekdayDisabled.includes((moment(start).isoWeekday()) - 1)){
      results.push(new Date(start));
    }
    start = moment(start).add(1, 'days').format("YYYY-MM-DD");
  }
  return holidayList.concat(results);
};

export const appointmentColorInfo = () =>{
  return [
    {
      label: "Unconfirmed",
      value: "Unconfirmed"
    },
    {
      label: "Confirmed",
      value: "Confirmed"
    },
    {
      label: "Buffer",
      value: "Buffer"
    },
    {
      label: "Serving",
      value: "Serving"
    },
    {
      label: "Completed",
      value: "Completed"
    },
    {
      label: "Cancel / Removed",
      value: "Removed"
    }
  ]
}

export const livePlanColorInfo = () =>{
  return [
    {
      label: "Waiting",
      value: "Waiting"
    },
    {
      label: "Serving",
      value: "Serving"
    },
    {
      label: "Skipped",
      value: "Skipped"
    }
  ]
}

export const normalizeFontSize = (size) => {
  const BASE_WIDTH = 1920;
  const BASE_HEIGHT = 1080;
  
  const scale = Math.min(SCREEN_WIDTH / BASE_WIDTH, SCREEN_HEIGHT / BASE_HEIGHT);
  return Math.max(Math.round(size * scale), size * 0.5); // Ensure minimum font size doesn't drop below 50% of original
};

export const updateNormalizeFontSize = () => {
  const { width, height } = Dimensions.get('window');
  SCREEN_WIDTH = width;
  SCREEN_HEIGHT = height;
};

export const getIsMobileDevice = () => {
  const { width } = useWindowDimensions();
   return width < 769
};

// export const generateTimeSlots = (start, end, interval) => {
//   const timeSlots = [];
//   let current = convertTo24Hour(start);
//   const endTime = convertTo24Hour(end);

//   while (current < endTime) {
//     const startTime = formatAMPM(current);
//     current.setMinutes(current.getMinutes() + interval);
//     const endTime = formatAMPM(current);
//     timeSlots.push({ start_time: startTime, end_time: endTime });
//   }
//   return timeSlots;
// };

export const generateTimeSlots = (start, end, interval) => {
  const timeSlots = [];
  let current = new Date();
  current.setHours(parseInt(start.split(":")[0]), parseInt(start.split(":")[1]), 0);
  
  const endTime = new Date();
  endTime.setHours(parseInt(end.split(":")[0]), parseInt(end.split(":")[1]), 0);

  while (current < endTime) {
    const startTime = `${current.getHours().toString().padStart(2, '0')}:${current.getMinutes().toString().padStart(2, '0')}`;
    current.setMinutes(current.getMinutes() + interval);
    const endTimeSlot = `${current.getHours().toString().padStart(2, '0')}:${current.getMinutes().toString().padStart(2, '0')}`;
    timeSlots.push({ start_time: startTime, end_time: endTimeSlot });
  }
  return timeSlots;
};


export const convertTo24Hour = (time) => {
  const [timePart, modifier] = time.split(' ');
  let [hours, minutes] = timePart.split(':').map(Number);

  if (modifier === 'PM' && hours !== 12) {
    hours += 12;
  } else if (modifier === 'AM' && hours === 12) {
    hours = 0;
  }

  return new Date(1970, 0, 1, hours, minutes);
};

export const formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
};

export const getQueueJoinShareLink = (id) => {
    return location.protocol + '//' + location.host + '/join-queue/' + id;
};

export const getTrackingLink = (id) => {
    return location.protocol + '//' + location.host + '/track-joined-queue-position/' + id;
};

export const getISOTimeFormat = (time) => (time ? moment(time, ['h:mm A']).format('HH:mm') : '');


export const convertHolidayResponseToNeedHolidayResponse = (holidayResponse) =>{
  let needHolidayResponse = [];
  for (const [date, details] of Object.entries(holidayResponse)) {
      let holidayObj = {
          date: date,
          serving_capacity: details?.serving_capacity ? details?.serving_capacity : 0,
          error: '',
          batch_size: details?.batch_size ? details?.batch_size : 0,
          batch_slot_duration: details?.batch_slot_duration ? details?.batch_slot_duration : 0,
          batchSizeError: '',
          slotDurationError: '',
      };
      needHolidayResponse.push(holidayObj);
  }
  return needHolidayResponse;
};

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
    const random = (Math.random() * 16) | 0;
    const value = char === 'x' ? random : (random & 0x3) | 0x8;
    return value.toString(16);
  });
};


export const convertWeekdayResponseToNeedResponse = (weekdayResponse) => {
 
  const convertTo12HourFormat = (time24) => {
      const [hour, minute] = time24.split(':');
      const suffix = +hour < 12 ? 'AM' : 'PM';
      const hour12 = ((+hour + 11) % 12 + 1);
      return `${hour12}:${minute} ${suffix}`;
  };

  let needResponse = [];
  
  for (const [day, details] of Object.entries(weekdayResponse)) {
      let dayObj = {
          id: parseInt(day),
          label: parseInt(day),
          short: parseInt(day),
          value: details?.is_closed === true ? false : true,
          isDisabled: false,
          startTime: details?.shifts[0] && details.shifts[0].start ? convertTo12HourFormat(details.shifts[0].start) : '',
          endTime: details?.shifts[0] && details.shifts[0].end ? convertTo12HourFormat(details.shifts[0].end): '',
          startTimeError: '',
          endTimeError: '',
          breakTimeList: []
      };
      
      for (const breakTime of details.break_times) {
          dayObj.breakTimeList.push({
              id: generateUUID(),
              startBreakTime: convertTo12HourFormat(breakTime.start),
              endBreakTime: convertTo12HourFormat(breakTime.end),
              startBreakTimeError: '',
              endBreakTimeError: ''
          });
      }
      
      needResponse.push(dayObj);
  }
  return needResponse;
};


export const getCurrentTimeZone = () => {
  return moment.tz.guess();
};